.iconButton {

  &.variant-normal, &.variant-bare {
    padding: 0.625em;

  }


  svg {
    min-width: 1.25em;
    min-height: 1.25em;
  }
}


